<template>
  <div class="container">
    <h2>Bienvenido</h2>
    <p v-if="user">Usuario: {{ user.displayName }}</p>
    <p v-else>Por favor, inicie sesión.</p>
  </div>
</template>

<script>
import { getRedirectResult } from "firebase/auth";
import { auth } from "../firebase/firebaseConfig";

export default {
  name: "ProtectedPage",
  data() {
    return {
      user: null
    };
  },
  async created() {
    try {
      const result = await getRedirectResult(auth);
      if (result) {
        this.user = result.user;
        console.log("Usuario autenticado:", this.user);
      }
    } catch (error) {
      console.error("Error al obtener el resultado de la redirección:", error);
    }
  }
};
</script>

<style scoped>
/* Agregar estilos adicionales si lo necesitas */
</style>