import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";



import 'bootstrap/dist/css/bootstrap.min.css'; // Importa los estilos CSS de Bootstrap
// import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap-icons/font/bootstrap-icons.css'


const firebaseConfig = {
    apiKey: "AIzaSyADLBKu3Gdez97PRTDsqKF3tOc23UYvMyI",
    authDomain: "colvm-69c48.firebaseapp.com",
    projectId: "colvm-69c48",
    storageBucket: "colvm-69c48.firebasestorage.app",
    messagingSenderId: "1857442121",
    appId: "1:1857442121:web:3e4f633d8b0ea982a08a98"
  };
  
  // Inicializa Firebase
  const firebaseApp = initializeApp(firebaseConfig);
  
  // Exporta Firestore para usarlo en otros componentes
  export const db = getFirestore(firebaseApp);
  
  // Crea la aplicación Vue
  createApp(App)
    .use(router) // Agrega Vue Router si lo usas
    .mount("#app");
