// src/firebase/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyADLBKu3Gdez97PRTDsqKF3tOc23UYvMyI",
  authDomain: "colvm-69c48.firebaseapp.com",
  projectId: "colvm-69c48",
  storageBucket: "colvm-69c48.firebasestorage.app",
  messagingSenderId: "1857442121",
  appId: "1:1857442121:web:3e4f633d8b0ea982a08a98"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Exporta la instancia de autenticación
const auth = getAuth(app);

// Exporta la configuración de Firebase y la instancia de auth
export { auth };